<template>
  <v-dialog v-model="modalDialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <wx-btn-standard
        v-bind="attrs"
        v-on="on"
        :title="$t('dashboard.manualRejectsEntry.addRejectsBtnHoverTitle')"
        @click="openModalDialog"
        :class="btnCssClass"
        class="add-rejects-activator"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t("dashboard.manualRejectsEntry.addRejectsBtn") }}
      </wx-btn-standard>
    </template>

    <article class="v-dialog__content">
      <header>
        <v-btn @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" class="close-btn" icon large>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="wx-typo-h1">{{ $t("dashboard.manualRejectsEntry.title") }}</h2>
      </header>
      <v-form ref="rejectsEntryForm" lazy-validation class="mt-6">
        <fieldset>
          <legend class="legend-header top-legend mb-2">{{ $t("dashboard.manualRejectsEntry.date") }}</legend>
          <v-row>
            <v-col cols="6" class="field-col">
              <wx-simple-calendar-date-picker
                v-model="date"
                :label="dateLabel"
                :timezone="getTimezone()"
                :limit-past-number-of-days="7"
                :error-messages="dateErrorMessages"
              />
            </v-col>
            <v-col cols="6" class="field-col">
              <wx-time-picker
                v-model="time"
                :title="timeLabel"
                :rules="[() => validateAndSetEffectiveDates()]"
                :error-messages="timeErrorMessages"
                :show-prepend-icon="false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="field-col">
              <wx-text-field
                v-model="quantity"
                ref="quantityInput"
                :rules="[() => validateQuantity()]"
                :label="$t('dashboard.manualRejectsEntry.quantityLabel')"
              />
            </v-col>
            <v-col cols="6" class="field-col">
              <wx-select
                v-model="unit"
                :items="availableQuantityUnits"
                :rules="[() => validateUnit()]"
                :label="$t('dashboard.manualRejectsEntry.unitLabel')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="field-col">
              <wx-autocomplete
                v-model="rejectReasonObject"
                :items="availableReasonsFlattenTree"
                :rules="[() => validateRejectReason()]"
                :label="$t('dashboard.manualRejectsEntry.rejectionReasonLegend')"
                :placeholder="$t('dashboard.manualRejectsEntry.rejectionReasonPlaceholder')"
              >
                <template v-slot:item="{ item }">
                  <span class="wx-typo-sm">{{ item.name }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="wx-typo-sm">{{ item.nameWhenSelected }}</span>
                </template>
              </wx-autocomplete>
              <v-textarea
                v-model="comment"
                :label="$t('dashboard.manualRejectsEntry.optionalComment')"
                :counter="commentMaxLength"
                :maxlength="commentMaxLength"
                rows="2"
                auto-grow
                clearable
                filled
                dense
              />
            </v-col>
          </v-row>
          <v-row v-if="this.validationError">
            <v-alert type="error" icon="$alertIcon" outlined text>
              <p>{{ this.validationErrorMessage }}</p>
            </v-alert>
          </v-row>
        </fieldset>
        <fieldset class="form-footer-actions mt-4">
          <wx-btn-standard @click="closeModalDialog" :title="$t('common.dialogFormCancelHoverTitle')" outlined>
            {{ $t("common.cancel") }}
          </wx-btn-standard>
          <wx-btn-standard
            id="pendo-submit-reject-btn"
            @click="submit"
            :disabled="!this.submitButtonEnabled"
            :title="$t('common.formSubmitHoverTitle')"
            color="primary"
          >
            {{ $t("common.submit") }}
            <v-icon right>mdi-chevron-right</v-icon>
          </wx-btn-standard>
        </fieldset>
      </v-form>
    </article>
  </v-dialog>
</template>
<script>
import * as TimeUtils from "@/store/TimeUtils";
import moment from "moment-timezone";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import WxSelect from "@/components/ui/WxSelect";
import WxSimpleCalendarDatePicker from "@/components/ui/WxSimpleCalendarDatePicker.vue";
import WxTextField from "@/components/ui/WxTextField";
import WxTimePicker from "@/components/ui/WxTimePicker.vue";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";
import ErrorHandling from "@/components/ErrorHandling";
import Validations from "@/components/validations";

function isValidDecimalGreaterThan(valueAsString, minValueExcluded) {
  let value = Number.parseFloat(valueAsString);
  if (isNaN(value)) {
    return false;
  } else {
    if (value <= minValueExcluded) {
      return false;
    }
  }
  return true;
}

export default {
  name: "ManualRejectsEntry",
  components: {
    WxAutocomplete,
    WxBtnStandard,
    WxSelect,
    WxSimpleCalendarDatePicker,
    WxTextField,
    WxTimePicker,
  },
  data() {
    return {
      modalDialog: false,

      rejectApplyDate: null,
      date: this.getDefaultEffectiveDate(),
      dateErrorMessages: [],
      time: this.getDefaultEffectiveTime(),
      timeErrorMessages: [],

      quantity: null,
      unit: "count",

      rejectReasonObject: null,

      comment: null,
      commentMaxLength: Validations.rejectCommentMaxLength,

      submitButtonEnabled: false,

      validationError: false,
      validationErrorMessage: "",
    };
  },
  props: {
    btnCssClass: {
      type: String,
      default: null,
    },
  },
  watch: {
    modalDialog() {
      if (this.modalDialog && this.activeFactory) {
        this.fetchAvailableRejectReasons()
          .then(() => (this.submitButtonEnabled = true))
          .catch(() => (this.submitButtonEnabled = true));
      }
    },
    unit() {
      this.$refs.quantityInput.validate();
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("dashboard", ["availableRejectReasons"]),
    dateLabel() {
      return this.$t("common.date");
    },
    timeLabel() {
      return this.$t("dashboard.manualRejectsEntry.time");
    },
    availableQuantityUnits() {
      return [
        {
          text: this.$t("common.units.unit"),
          value: "count",
        },
        {
          text: `${this.$t("common.unitNames.kilogram")} (${this.$t("common.units.kilogram")})`,
          value: "kg",
        },
        {
          text: `${this.$t("common.unitNames.gram")} (${this.$t("common.units.gram")})`,
          value: "g",
        },
        {
          text: `${this.$t("common.unitNames.pound")} (${this.$t("common.units.pound")})`,
          value: "lb",
        },
        {
          text: `${this.$t("common.unitNames.ounce")} (${this.$t("common.units.ounce")})`,
          value: "oz",
        },
        {
          text: `${this.$t("common.unitNames.meter")} (${this.$t("common.units.meter")})`,
          value: "m",
        },
        {
          text: `${this.$t("common.unitNames.feet")} (${this.$t("common.units.feet")})`,
          value: "ft",
        },
        {
          text: `${this.$t("common.unitNames.liter")} (${this.$t("common.units.liter")})`,
          value: "L",
        },
        {
          text: `${this.$t("common.unitNames.milliliter")} (${this.$t("common.units.milliliter")})`,
          value: "mL",
        },
      ];
    },
    availableReasonsFlattenTree() {
      const selectorElements = [];
      for (let element of this.availableRejectReasons) {
        if (element.type === "category") {
          selectorElements.push({ divider: true });
          selectorElements.push({ header: element.name });
        } else {
          selectorElements.push({
            name: element.name,
            nameWhenSelected: element.name,
            id: element.id,
          });
        }
      }
      return selectorElements;
    },
  },
  methods: {
    ...mapActions("dashboard", ["fetchAvailableRejectReasons", "submitRejects"]),
    ...mapActions("operation", ["showOperationError"]),
    openModalDialog() {
      this.submitButtonEnabled = false;
      this.modalDialog = true;
    },
    closeModalDialog() {
      this.$refs.rejectsEntryForm.resetValidation();
      this.reset();
    },
    getDefaultEffectiveDate() {
      return TimeUtils.getTodayDate(this.getTimezone());
    },
    getDefaultEffectiveTime() {
      return TimeUtils.getCurrentTime(this.getTimezone());
    },
    getTimezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    validateQuantity() {
      if (!this.quantity) {
        return this.$t("dashboard.manualRejectsEntry.errors.invalidQuantity");
      }
      if (String(this.quantity).length > 0 && !isValidDecimalGreaterThan(this.quantity, 0.0)) {
        return this.$t("dashboard.manualRejectsEntry.errors.invalidQuantity");
      }
      if (!Number.isInteger(Number(this.quantity)) && this.unit === "count") {
        return this.$t("dashboard.manualRejectsEntry.errors.invalidUnitQuantity");
      }
      return true;
    },
    validateUnit() {
      if (!this.unit) {
        return this.$t("dashboard.manualRejectsEntry.errors.noUnit");
      }
      const found = this.availableQuantityUnits.find((u) => u.value === this.unit);
      if (!found) {
        return this.$t("dashboard.manualRejectsEntry.errors.invalidUnit", { unit: this.unit });
      }
      this.validationError = false;
      this.validationErrorMessage = "";
      return true;
    },
    validateRejectReason() {
      if (!this.rejectReasonObject) {
        return this.$t("dashboard.manualRejectsEntry.errors.noRejectReason");
      }
      return true;
    },
    resetDateValidations() {
      this.dateErrorMessages = [];
      this.timeErrorMessages = [];
      this.validationError = false;
      this.validationErrorMessage = "";
    },
    validateAndSetEffectiveDates() {
      let applyDate = null;
      this.resetDateValidations();

      let dateAsDateTime = null;
      let timeAsDateTime = null;

      if (this.date) {
        dateAsDateTime = DateTime.fromFormat(this.date, TimeUtils.DATE_FORMAT);
        if (!dateAsDateTime.isValid) {
          this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.invalidDate")];
          return false;
        }
      } else {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.dateMissing")];
        return false;
      }
      if (this.time) {
        timeAsDateTime = DateTime.fromFormat(this.time, TimeUtils.TIME_FORMAT_HH_MM);
        if (!timeAsDateTime.isValid) {
          this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.invalidTime")];
          return false;
        }
      } else {
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.timeMissing")];
        return false;
      }

      // At this point, the Apply date & time are valid
      applyDate = dateAsDateTime.set({
        hour: timeAsDateTime.hour,
        minute: timeAsDateTime.minute,
        second: 0,
        millisecond: 0,
      });

      // Is applyDate in the past?
      const now = DateTime.now().set({ second: 0, millisecond: 0 });
      if (applyDate > now) {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
        return false;
      }

      // OK. It is set in the past. Does it exceed the limit?
      const pastLimit = now.minus({ days: 7 });
      if (applyDate < pastLimit) {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.pastLimitDate")];
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.pastLimitDate")];
        return false;
      }

      this.rejectApplyDate = applyDate ? applyDate.toISO() : DateTime.now().toISO();
      return true;
    },
    submit() {
      this.resetDateValidations();
      let isValid = this.$refs.rejectsEntryForm.validate();
      if (isValid) {
        let payload = {
          reject_date: this.rejectApplyDate,
          quantity: this.quantity,
          unit: this.unit,
          reason_id: this.rejectReasonObject.id,
          comment: this.comment,
        };
        this.submitRejects({ rejectPayload: payload })
          .then(() => this.closeModalDialog())
          .catch((error) => this.handleRejectError(error.response));
      }
    },
    handleRejectError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code, args, message) {
      if (code === "RS_MANUAL_REJECT_CREATION_POST_10011") {
        const regex = /The provided reject unit '(.*)' is not compatible with product target unit '(.*)' for SKU '(.*)'/gm;
        let matches = regex.exec(message);
        let unit = !!matches && matches.length >= 4 ? matches[1] : "";
        let targetUnit = !!matches && matches.length >= 4 ? matches[2] : "";
        let sku = !!matches && matches.length >= 4 ? matches[3] : "";
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.unitIncompatible", {
          unit: unit,
          targetUnit: targetUnit,
          sku: sku,
        });
        return null;
      } else if (code === "RS_MANUAL_REJECT_CREATION_POST_10012") {
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.outOfProduction");
        return null;
      } else if (code === "RS_MANUAL_REJECT_CREATION_POST_10015") {
        const regex = /The rejected quantity unit is of type '(.*)', but no target unit configured for sku '(.*)'/gm;
        let matches = regex.exec(message);
        let unit = !!matches && matches.length >= 3 ? matches[1] : "";
        let sku = !!matches && matches.length >= 3 ? matches[2] : "";
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.noTargetUnit", {
          unit: unit,
          sku: sku,
        });
        return null;
      } else if (code === "RS_MANUAL_REJECT_CREATION_POST_10017") {
        this.validationError = true;
        this.validationErrorMessage = this.$t("dashboard.manualRejectsEntry.errors.noSku");
        return null;
      }
      return this.$t("common.errors.default", { code: code });
    },
    reset() {
      this.rejectApplyDate = null;
      this.date = this.getDefaultEffectiveDate();
      this.dateErrorMessages = [];
      this.time = this.getDefaultEffectiveTime();
      this.timeErrorMessages = [];
      this.quantity = null;
      this.unit = "count";
      this.rejectReasonObject = null;
      this.comment = null;
      this.submitButtonEnabled = false;
      this.modalDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 420px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }

    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;

        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
